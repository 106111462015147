export const ActionTecForm = {
    title: "Create Account",
    titleIcon: "fal fa-users",

    fields:[
        {
            key:"accountName",
            type:"input",
            label:"Account Name",
            inputType:"text",
            placeholder: "Account Name",
        },
        {
            key:"customerName",
            type:"input",
            label:"Customer Name",
            inputType:"text",
            placeholder: "Customer Name",
        },
        {
            key:"environment",
            type:"input",
            label:"Environment",
            inputType:"text",
            placeholder: "Environment",
        },
        {
            key:"expiry",
            type:"select",
            label:"Expiry",
            placeholder: "Options",
            options: [
                { value: "manualData", label: "Manual Data" },
                { value: "apiData", label: "Call API" },
            ]
        },
        
    ],

    actions: {
        submit:{
            label: "Generate Form Key"
        }
    }
}
