import React, { useEffect, useMemo, useState, useRef } from "react"
import {
	useTable,
	usePagination,
	useSortBy,
	useFilters,
	useGroupBy,
	useExpanded,
	useRowSelect,
	useAsyncDebounce,
} from 'react-table'

import { EditableCell, filterTypes, IndeterminateCheckbox } from './tableRequiredFunc'

export default function TableSkeleton({ columns, data = [], updateMyData, isPagination, defaultPageSize, hideGoToPage, hidePageSize, isCellsEditable, isFiltering, isGroupBy, isSortBy, isMultiSort, isSelectable, searchFilterPlaceholder, isDebounce, debounceDuration, isSSR, loading, pageCount: controlledPageCount, onFilteredChange, onSortByChange, onPageChange, onChangeHideColumns, manualHiddenColumns, hideableColumns, preventFirstCall, onRowClick, totalRecords, NoDataFoundComponent, LoadingComponent, onRowSelection, paginationIcons, sortingIcons, initialState }) {

	columns?.filter(el => el.Filter).map((el) => {
		switch (el.Filter) {
			case 'SliderColumnFilter':
				el.Filter = SliderColumnFilter
				break
			case 'NumberRangeColumnFilter':
				el.Filter = NumberRangeColumnFilter
				break
			case 'SelectColumnFilter':
				el.Filter = SelectColumnFilter
				break
		}
	})

	const debounceFilter = useAsyncDebounce((value, setFilter) => {
		setFilter(value)
	}, debounceDuration)

	function placeHolderHandler(columnResult) {
		if (columnResult.length > 0) {
			if (columnResult[0].filterPlaceholder) {
				return columnResult[0].filterPlaceholder;
			} else {
				return searchFilterPlaceholder
			}
		} else {
			return searchFilterPlaceholder
		}
	}

	function DefaultColumnFilter({
		column: { filterValue, width, setFilter, id }, columns
	}) {
		const [value, setValue] = useState(filterValue)
		const columnResult = columns.filter((column) => {
			return column.id === id
		})
		return (
			<React.Fragment>
				<input
					value={value || ''}
					onChange={e => {
						setValue(e.target.value);
						isDebounce ?
							debounceFilter(e.target.value || undefined, setFilter) :
							setFilter(e.target.value || undefined)
					}}
					placeholder={placeHolderHandler(columnResult)}
					style={{ width: width }}
				/>
			</React.Fragment>
		)
	}

	function SelectColumnFilter({
		column: { filterValue, setFilter, preFilteredRows, id }, columns
	}) {

		const [value, setValue] = useState(filterValue)

		const options = useMemo(() => {
			const options = new Set()
			const columnResult = columns.filter((column) => {
				return column.id === id
			})
			columnResult.length > 0 && columnResult[0].selectOptions && columnResult[0].selectOptions.length > 0 && columnResult[0].selectOptions.forEach(option => {
				options.add(option)
			})
			return [...options.values()]
		}, [id, preFilteredRows])

		return (
			<select
				value={value}
				onChange={e => {
					setValue(e.target.value);
					isDebounce ?
						debounceFilter(e.target.value || undefined, setFilter) :
						setFilter(e.target.value || undefined)
				}}
			>
				<option value="">All</option>
				{options.map((option, i) => (
					<option key={`${JSON.stringify(i)}`} value={option}>
						{option}
					</option>
				))}
			</select>
		)
	}


	function SliderColumnFilter({
		column: { filterValue, setFilter, preFilteredRows, id }
	}) {
		const [value, setValue] = useState(filterValue)

		const [min, max] = useMemo(() => {
			let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
			let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
			preFilteredRows.forEach(row => {
				min = Math.min(row.values[id], min)
				max = Math.max(row.values[id], max)
			})
			return [min, max]
		}, [id, preFilteredRows])

		return (
			<React.Fragment>
				<input
					type="range"
					min={min}
					max={max}
					value={value || min}
					onChange={e => {
						setValue(e.target.value);
						isDebounce ?
							debounceFilter(parseInt(e.target.value, 10) || undefined, setFilter) :
							setFilter(parseInt(e.target.value, 10))
					}}
				/>
				<button onClick={() => { setFilter(undefined); setValue(undefined); }}>Off</button>
			</React.Fragment>
		)
	}

	function NumberRangeColumnFilter({
		column: { filterValue = [], preFilteredRows, setFilter, id }
	}) {
		const [value, setValue] = useState(filterValue[0])
		const [value2, setValue2] = useState(filterValue[1])
		const [min, max] = useMemo(() => {
			let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
			let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
			preFilteredRows.forEach(row => {
				min = Math.min(row.values[id], min)
				max = Math.max(row.values[id], max)
			})
			return [min, max]
		}, [id, preFilteredRows])

		return (
			<div
				style={{
					display: 'flex',
				}}
			>
				<input
					value={value || ''}
					type="number"
					onChange={e => {
						setValue(e.target.value);
						isDebounce ?
							debounceFilter((old = []) => [e.target.value ? parseInt(e.target.value, 10) : undefined, old[1]], setFilter) :
							setFilter((old = []) => [e.target.value ? parseInt(e.target.value, 10) : undefined, old[1]])
					}}
					placeholder={`Min (${min})`}
					style={{
						width: '70px',
						marginRight: '0.5rem',
					}}
				/>
				to
				<input
					value={value2 || ''}
					type="number"
					onChange={e => {
						setValue2(e.target.value);
						isDebounce ?
							debounceFilter((old = []) => [old[0], e.target.value ? parseInt(e.target.value, 10) : undefined], setFilter) :
							setFilter((old = []) => [old[0], e.target.value ? parseInt(e.target.value, 10) : undefined])
					}}
					placeholder={`Max (${max})`}
					style={{
						width: '70px',
						marginLeft: '0.5rem',
					}}
				/>
			</div>
		)
	}

	const defaultColumn = useMemo(
		() => ({
			...(isFiltering && { Filter: DefaultColumnFilter }),
			...(isCellsEditable && { Cell: EditableCell })
		}),
		[]
	)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		page,
		allColumns,
		getToggleHideAllColumnsProps,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		selectedFlatRows,
		setHiddenColumns,
		state: {
			pageIndex,
			pageSize,
			sortBy,
			filters,
			selectedRowIds,
			hiddenColumns
		},
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes,
			updateMyData,
			autoResetSortBy: false,
			autoResetHiddenColumns: false,
			disableMultiSort: !isMultiSort,
			...(isSSR && {
				manualPagination: true,
				manualFilters: true,
				manualSortBy: true,
				initialState: { pageIndex: 0, pageSize: defaultPageSize, ...initialState },
				pageCount: controlledPageCount,
			}),
		},
		isFiltering && useFilters,
		isGroupBy && useGroupBy,
		isSortBy && useSortBy,
		useExpanded,
		usePagination,
		isSelectable && useRowSelect,
		isSelectable &&
		(hooks => {
			hooks.visibleColumns.push(columns => {
				return [
					{
						id: "selection",
						groupByBoundary: true,
						click: "disabled",
						Header: ({ getToggleAllRowsSelectedProps }) => (
							<div>
								<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
							</div>
						),
						Cell: ({ row }) => (
							<div>
								<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
							</div>
						),
					},
					...columns,
				];
			});
		})
	);
	const callFuncRef = useRef(preventFirstCall);
	const callFilterFuncRef = useRef(preventFirstCall);
	const callSortFuncRef = useRef(preventFirstCall);
	const callRowSelectionRef = useRef(preventFirstCall);

	useEffect(() => {
		!callFuncRef.current && isSSR && onPageChange && onPageChange({ pageIndex, pageSize });
		callFuncRef.current = false;
	}, [pageIndex, pageSize]);

	useEffect(() => {
		!callFilterFuncRef.current && isSSR && onFilteredChange && onFilteredChange(filters);
		callFilterFuncRef.current = false;
	}, [filters]);

	useEffect(() => {
		!callSortFuncRef.current && isSSR && onSortByChange && onSortByChange(sortBy);
		callSortFuncRef.current = false;
	}, [sortBy]);

	useEffect(() => {
		onChangeHideColumns?.(hiddenColumns);
	}, [hiddenColumns]);

	useEffect(() => {
		!callRowSelectionRef.current && isSSR && onRowSelection && onRowSelection(selectedRowIds, selectedFlatRows);
		callRowSelectionRef.current = false;
	}, [selectedRowIds]); // Add 'data' to the dependency array

	useEffect(() => {
		if (manualHiddenColumns && manualHiddenColumns.length > 0) {
			setHiddenColumns(manualHiddenColumns)
		}
	}, [manualHiddenColumns])


	function getAllDisabledIndexes(cells) {
		let indexes = [], i = -1;
		for (let i = 0; i <= cells.length - 1; i++) {
			if (cells[i].column.click === 'disabled') {
				indexes.push(i)
			}
		}
		return indexes;
	}

	function cellHolder(cell) {
		if (cell.isAggregated) {
			return (cell.render('Aggregated'))
		} else if (cell.isPlaceholder) {
			return null
		} else {
			return (cell.render('Cell', { editable: true }))
		}
	}

	function columnHandler(column) {
		if (column.isSorted) {
			if (column.isSortedDesc) {
				return <i className={sortingIcons?.desc} /> || ' 🔽'
			} else {
				return <i className={sortingIcons?.asc} /> || ' 🔼'
			}
		} else {
			return '';
		}
	}

	return (
		<React.Fragment>
			{hideableColumns && <div>
				<div>
					<IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle
					All
				</div>
				{allColumns.map(column => (
					<div key={column.id}>
						<label>
							<input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
							{column.id}
						</label>
					</div>
				))}
				<br />
			</div>}
			<div className="table83-parent">
				<table {...getTableProps()}>
					<thead>
						{ }
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<th {...column.getHeaderProps({
										style: { width: column.width },
									})}>
										<div>
											{column.canGroupBy ? (
												<span {...column.getGroupByToggleProps()}>
													{column.isGrouped ? '🛑 ' : '👊 '}
												</span>
											) : null}
											{
												isSortBy ? <span {...column.getSortByToggleProps()}>
													{column.render('Header')}
													{columnHandler(column)}
												</span> : column.render('Header')
											}
										</div>
										<div>{column.canFilter ? column.render('Filter') : null}</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{loading ? (
							<tr><td colSpan="10000">{LoadingComponent ? LoadingComponent : 'Loading...'}</td></tr>
						) : data.length === 0 ? (
							<tr><td colSpan="10000">{NoDataFoundComponent ? NoDataFoundComponent : 'No rows found'}</td></tr>
						) : (
							(isPagination ? page : rows).map((row, rowIndex) => {
								prepareRow(row);
								return (
									<tr
										{...row.getRowProps()}
										style={onRowClick ? { cursor: "pointer" } : {}}
									>
										{row.cells.map((cell, cellIndex) => {
											return (
												<td
													{...cell.getCellProps({
														style: {
															width: cell.column.width,
															cursor: !getAllDisabledIndexes(row.cells).includes(cellIndex) && onRowClick ? 'pointer' : 'default'
														},
													})}
													onClick={!getAllDisabledIndexes(row.cells).includes(cellIndex) && onRowClick ? () => onRowClick(row) : undefined}
												>
													{cell.isGrouped ? (
														<React.Fragment>
															<span {...row.getToggleRowExpandedProps()}>
																{row.isExpanded ? '👇' : '👉'}
															</span>{' '}
															{cell.render('Cell', { editable: false })} (
															{row.subRows.length})
														</React.Fragment>
													) : cellHolder(cell)}
												</td>
											);
										})}
									</tr>
								);
							})
						)}
					</tbody>
				</table>
			</div>
			{isPagination &&
				<div className="pagination display-flex justify-content-between">
					<span>
						{totalRecords} Total Results
					</span>
					<div className="pagination-buttons">
						<div className="display-flex align-items-center">
							<span> Items per page </span>
							{!hidePageSize && <select className="form83-control"
								value={pageSize}
								onChange={e => {
									setPageSize(Number(e.target.value))
								}}
							>
								{[10, 20, 30, 40, 50].map(pageSize => (
									<option key={pageSize} value={pageSize}>
										{pageSize}
									</option>
								))}
							</select>}
							<span>

								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{' '}
							</span>
							<button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="button83 button83-default">
								{paginationIcons?.first ? <i className={paginationIcons.first} /> : <i className="far fa-angle-double-left"></i>}
							</button>{' '}
							<button onClick={() => previousPage()} disabled={!canPreviousPage} className="button83 button83-default">
								{paginationIcons?.previous ? <i className={paginationIcons?.previous} /> : <i className="far fa-angle-left"></i>}
							</button>{' '}
							<button onClick={() => nextPage()} disabled={!canNextPage} className="button83 button83-default">
								{paginationIcons?.next ? <i className={paginationIcons?.next} /> : <i className="far fa-angle-right"></i>}
							</button>{' '}
							<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="button83 button83-default">
								{paginationIcons?.last ? <i className={paginationIcons?.last} /> : <i className="far fa-angle-double-right"></i>}
							</button>{' '}
						</div>
					</div>
				</div>
			}
		</React.Fragment>
	)
}