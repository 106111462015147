import { msalInstance } from "../pages/Login/msalConfig";
import { removeAuthState } from "../pages/Login/Auth";

export const timeStampDifference = (timestamp) => {

    if (!timestamp) {
        return "N/A";
    }
    let currentTimestamp = Date.now(),
        timeDiffInMiliseconds = timestamp - currentTimestamp;

    if (currentTimestamp > timestamp) {
        return "Expired";
    }
    let totalSeconds = parseInt(Math.floor(timeDiffInMiliseconds / 1000)),
        totalMinutes = parseInt(Math.floor(totalSeconds / 60)),
        totalHours = parseInt(Math.floor(totalMinutes / 60)),
        days = parseInt(Math.floor(totalHours / 24));

    if (days) {
        if (days > 7 && days < 30) {
            return `${Math.floor(days / 7)} ${days / 7 > 1 ? "weeks" : "week"} ${days % 7} ${days % 7 > 1 ? "days" : "day"} `;
        } else if (days >= 30 && days <= 360) {
            return `${Math.floor(days / 30)} ${days / 30 > 1 ? "months" : "month"} ${days % 30} ${days % 30 > 1 ? "days" : "day"} `;
        } else if (days >= 360) {
            return `${(days / 365).toFixed(1)} ${days / 365 > 1 ? "years" : "year"}`;
        }
        else {
            return `${days} ${days > 1 ? "days" : "day"} `;
        }

    } else if (totalHours) {
        return `${totalHours} ${totalHours > 1 ? "hours" : "hour"} `;
    } else if (totalMinutes) {
        return `${totalMinutes} ${totalMinutes > 1 ? "minutes" : "minute"} `;
    } else {
        return `${totalSeconds} ${totalSeconds > 1 ? "seconds" : "second"} `;
    }
};

export function convertTimestampDateTime(timestamp) {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 as month is zero-based
    const year = String(date.getFullYear()).slice(-2); // Extracting last two digits of the year
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const dateTime = `${day}/${month}/${year} , ${hours}:${minutes}`;
    return dateTime;
}

export function getTimeForward(value, expiry) {


    let currentTime = Date.now()
    let futureTime;
    if (expiry === 0) {
        futureTime = currentTime + parseInt(value);

    } else if (expiry < currentTime) {
        futureTime = currentTime
    } else {
        futureTime = expiry + parseInt(value);
    }

    return futureTime
}

export const setActiveAccount = () => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }
};

export const handleLogout = async () => {
    try {
        const logoutPromise = msalInstance.logout({
            account: msalInstance.getActiveAccount(),
            postLogoutRedirectUri: `${window.location.origin}/login`
        });

        const removeAuthStatePromise = removeAuthState();

        await Promise.all([logoutPromise, removeAuthStatePromise]);
        // Redirect to the login page if needed
        window.location.href = `${window.location.origin}/login`;
    } catch (error) {
        console.error("Logout error:", error);
    }
}

export function extractOptionsFromArray(dataArray, valueKey, labelKey) {
    return dataArray?.map(item => ({ value: item[valueKey], label: item[labelKey] }));
}
