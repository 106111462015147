/**
 *
 * Status83
 *
 */

import React, { memo } from "react";
function Status(props) {
  const { label, statusType, item, type } = props
  const classNameHandler = () => {
    let commonClass = 'status-text '
    switch (statusType) {
      case 'success':
        commonClass += 'status-offline'
        break;
      case 'info':
        commonClass += 'status-pending'
        break;
      case 'warning':
        commonClass += 'status-offline'
        break
      default:
        break;
    }
    return commonClass
  }
  const labelClassNameHandler = () => {
    let className = 'status-badge status-badge-'
    switch (label) {
      case 'Active':
        className += 'active'
        break;
      case 'Created':
        className += 'primary'
        break;
      case 'Expiry':
        className += 'Inactive'
        break
      case 'Suspended':
        className += 'warning'
        break
      default:
        break;
    }
    return className
  }

  const handleStatusIconClass = () => {
    let commonClass = 'far fa-'
    switch (label) {
      case 'Expired':
        commonClass += 'times'
        break;
      case 'Suspended':
        commonClass += 'exclamation'
        break;
      default:
        commonClass += 'check'
        break;
    }
    return commonClass
  }

  return (
    <React.Fragment>
      <div className="status83-wrapper">
        {type === 'tag' ? <span className={labelClassNameHandler()}>
          {statusType && <span className="status-badge-icon circle" ><i className={handleStatusIconClass()}></i></span>}
          {label}
        </span> :
          <div className='status circle'>
            <p className={classNameHandler()}>{item ?? "N/A"}</p>
          </div>
        }
      </div>
    </React.Fragment >
  );
}

Status.propTypes = {};

export default memo(Status);
