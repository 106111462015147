/**
 *
 * Loader83
 *
 */

import React, { memo } from "react";

function Loader83(props) {
  return (
    <div className="loader83-container">
      <div className="loader83">
        {props.loaderType === "fullscreen" ?
          <div className="fullscreen-loader-wrapper">
            <div className="fullscreen-loader">
              <img src={require('../../assets/images/flex-logo.png')} />
              <div className="fullscreen-inner-loader">
                <span className="black-circle"></span>
              </div>
            </div>
            <div className="horizontal-line"></div>
          </div> :
          <div className="small-loader-wrapper">
            <img src={require('../../assets/images/f83.png')} />
            <span className="small-loader"></span>
          </div>
        }
      </div>
    </div>
  );
}

Loader83.propTypes = {};

export default memo(Loader83);
