import React, { memo } from "react";
import { msalInstance } from "../../pages/Login/msalConfig";
import { removeAuthState } from "../../pages/Login/Auth";
import Button83 from "../Button83";

function Logout() {
    const handleLogout = async () => {
        try {
            await msalInstance.logout({
                account: msalInstance.getActiveAccount(),
                postLogoutRedirectUri: `${window.location.origin}/login`
            });
        } catch (error) {
            console.error('Error logging out:', error);
        }
        await removeAuthState()
    };

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Button83 className="button83-standard logout83 margin-left-10" icon="far fa-power-off" dataTip="Logout" dataFor='Logout' onClick={handleLogout} />
        </div>
    )
}

export default memo(Logout)