import React from "react";
import Card83 from "../../components/Card83"

function Footer83() {
  const handleLinkClick = (event) => {
    event.preventDefault();
    window.open(event.target.href, '_blank');
  };
  return (
    <React.Fragment>
      <footer className="footer83">
        <ul className="list-style-none">
          <li><span className="fw-700">©IoT83 2023 </span>- All Rights Reserved</li>
          <li className="position-relative cursor-pointer">
            <a href="#" className="">Version Info</a>
            <div className="custom-footer83-tooltip">
              <Card83 disableHeader className='footer-detail'>
                {Object.entries(window.APP_CONFIG).map(([key, value]) => {
                  return <>
                    {(key === 'CHART_VERSION' || key === 'APP_VERSION' || key === 'DEPLOY_DATE' || key === 'RELEASE_VERSION' || key === 'RELEASE_NAME') ? <div className="footer-info"><h6>{key} : </h6> <p>{value}</p></div> : null}
                  </>
                })}
              </Card83>
            </div>
          </li>
        </ul>

        <ul>
          <li>
            <a href="https://www.iot83.com/about-us/#About" onClick={handleLinkClick}>About Us</a>
          </li>

          <li>
            <a href="https://www.iot83.com/privacy/" onClick={handleLinkClick}>Privacy Policy</a>
          </li>

          <li>
            <a href="https://www.iot83.com/terms-and-conditions/" onClick={handleLinkClick}>Terms & Conditions</a>
          </li>

          <li>
            <a href="https://www.iot83.com/contact-us/" onClick={handleLinkClick}>Help</a>
          </li>
        </ul>
      </footer>
    </React.Fragment>
  );
}

export default Footer83;