import { PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';

const uri = window.location.origin
const azureConfig = {
    auth: {
        clientId: window.APP_CONFIG.MS_CLIENTID,
        authority: window.APP_CONFIG.MS_AUTHORITY,
        redirectUri: `${uri}/login`,
        postLogoutRedirectUri: `${uri}/login`
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Specify the cache location
        storeAuthStateInCookie: false // Optional, set to true if you want to store auth state in cookies as a fallback
    }
};

export default azureConfig;
export const msalInstance = new PublicClientApplication(azureConfig);
