/************************************************************************
 * 83incs CONFIDENTIAL
 * ***********************************************************************
 *
 *  [2017] - [2022] 83incs Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of 83incs Ltd, IoT83 Ltd, its suppliers (if any), its subsidiaries (if any) and
 * Source Code Licenses (if any).  The intellectual and technical concepts contained
 * herein are proprietary to 83incs Ltd, IoT83 Ltd, its subsidiaries (if any) and
 * Source Code Licenses (if any) and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from 83incs Ltd or IoT83 Ltd.
 ****************************************************************************
 */
/**
 *
 * Card83
 *
 */



import React, { useEffect, useState, memo } from 'react'
import Button83 from '../Button83'

function Card83(props) {
  const { cardData, onAction, className, children, onChangeCardData, modeType, disableHeader, scrollableBody, } = props
  const [editCard, setEditCard] = useState(false)
  const [cardTitle, setCardTitle] = useState(cardData?.title);
  const [cardInfo] = useState(cardData?.info);
  useEffect(() => {
    onChangeCardData && onChangeCardData({ title: cardTitle })
  }, [cardTitle])

  useEffect(() => {
    if (cardTitle !== cardData?.title) {
      setCardTitle(cardData?.title)
    }
  }, [cardData])

  function getClassHandler(item) {
    switch (item) {
      case "card83":
        if (className) {
          return `card83 ${className}-card83`
        } else {
          return "card83"
        }
      case "header83":
        if (className) {
          return `card83-header ${className}-card83-header`
        } else {
          return 'card83-header'
        }
      case "title83":
        if (className) {
          return `card83-title ${className}-card83-title`
        } else {
          return 'card83-title'
        }
      case "action83":
        if (className) {
          return `card83-actions display-flex card83-actions-${className}`
        } else {
          return "card83-actions display-flex"
        }
      default: return ""
    }
  }

  function getChilderHandler() {
    if (cardData?.component) {
      return cardData?.component
    } else {
      if (children) {
        return children;
      } else {
        return "";
      }
    }
  }

  function getBodyHandler() {
    if (disableHeader) {
      return 'card83-body card83-no-header'
    } else if (className) {
      return `card83-body ${className}-card83-body`
    } else if (scrollableBody) {
      return `card83-body card83-body-scrollable`
    } else {
      return 'card83-body'
    }
  }

  return (
    (cardData || disableHeader) && <div key={props?.id} className={getClassHandler('card83')}>
      {!disableHeader && <div className={getClassHandler('header83')}>
        <div className={getClassHandler('title83')}>
          {cardData.icon && <i className={cardData.icon} />}
          {!editCard ? <h6 className={modeType === 'viewMap' ? "pointer-events-none" : ''} onDoubleClick={() => setEditCard(true)}>{cardTitle} <span>{cardInfo}</span> </h6> :
            <input type="text" className='form-control' value={cardTitle} autoFocus onChange={(e) => { setCardTitle(e.target.value) }} onBlur={() => setEditCard(false)} />}
        </div>
        {props.selectBox}
        <div className={getClassHandler('action83')}>
          {modeType && modeType !== "view" &&
            <Button83 onClick={() => setEditCard(true)} variant='icon' icon='fal fa-pencil' dataTip="Edit" dataFor="chartConfig" />
          }
          {props.childComponents && props.childComponents()}
          {cardData?.actions && cardData?.actions.length > 0 && cardData?.actions?.map((action) => {
            return <Button83 onClick={() => onAction && onAction(action.key)} {...action} />
          })}
        </div>
      </div>}

      <div className={getBodyHandler()}
      >
        {getChilderHandler()}
      </div>
    </div>
  )
}

export default memo(Card83)
