/**
 *
 * Modal83
 *
 */

import React, { memo } from 'react';
import Button83 from '../Button83';
import Status83 from '../Status83';
function Modal83({ additionalHeading, isVisible, isDeleteButtonDisabled, isEditOrAddButtonDisabled = false, onConfirm, onCancel, onSubmit, disabled, customclass, onDeleteInputChange, ...props }) {


    return (
        <React.Fragment>
            {isVisible &&
                <div className={customclass ? `modal-wrapper slidingPane sliding-modal83 sliding-modal83-transition ${customclass}` : "modal-wrapper slidingPane sliding-modal83 sliding-modal83-transition"} >
                    <div className={`modal-dialog ${props.modalType === "delete" ? 'modal-sm' : props.modalWidth} modal-dialog-scrollable`}>
                        <div className="modal-content">
                            <div className={`modal-header ${props.modalType === "delete" ? "modal-header-delete" : ""}`}>
                                <div className='display-flex align-items-center'>
                                    <h5 >{props.modalHeading}</h5>
                                    <h6 className='margin-left-10'><Status83 statusType={additionalHeading} label={additionalHeading} type={"tag"} /></h6>
                                </div>
                                <Button83 className='button83-icon' icon="fal fa-times" onClick={() => onCancel()} ></Button83>
                            </div>
                            {props.modalType === "delete" ?
                                <div className='delete-modal'>
                                    <div className='delete-modal-content'>
                                        <div className='delete-modal-img'>
                                            <img src={require('../../assets/images/delete-modal-img.png')} />
                                        </div>
                                    </div>
                                    <div className='delete-modal-text'>
                                        <p> Are you sure you want to delete this account <span>{props.deleteItemName}</span> ?</p>
                                    </div>

                                    <div className='form83-group'>
                                        <input type='text' placeholder='Please type Delete' onChange={(event) => onDeleteInputChange(event)} className='form83-control' />
                                    </div>

                                </div>

                                :
                                <div className='modal-scrollable'>
                                    <div className="modal-body">
                                        {props.children}
                                    </div>
                                </div>
                            }
                            <div className="modal-footer">

                                <Button83 className="button83-outlined-default" label="Close" onClick={() => onCancel()} />
                                {!props.hideSubmitButton &&
                                    <Button83 disabled={isDeleteButtonDisabled || isEditOrAddButtonDisabled} className={props.modalType === "delete" ? "button83-danger margin-left-15" : "button83-primary margin-left-15"} label={props.modalType === "delete" ? "Delete" : "Save"} onClick={onConfirm ?? onSubmit} />
                                }
                            </div>
                        </div>
                    </div>
                </div>}
        </React.Fragment>
    );
}

Modal83.propTypes = {};

export default memo(Modal83);
