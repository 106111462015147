import React from "react";
import Button83 from "../Button83";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

function Login() {
    const { instance } = useMsal();
    const navigate = useNavigate()
    const handleLogin = async () => {
        const loginRequest = {
            scopes: [window.APP_CONFIG.MS_SCOPE]
        };
        try {
            const loginResponse = await instance.loginPopup(loginRequest);
            navigate('/customers');
            localStorage.setItem("token", `Bearer ${loginResponse.accessToken}`);
        } catch (error) {
            console.log('error: ', error);
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Button83 className="button83-primary margin-right-10" label="Login with Microsoft" icon="fab fa-microsoft" onClick={handleLogin}></Button83>
        </div>
    )

}

export default Login