import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import UserProfile from '../UserProfile';
import Logout from '../Logout';
import { useNavigate } from 'react-router-dom';

function Header83({ className, ...props }) {
  const isLoggedIn = useIsAuthenticated()
  const navigate = useNavigate()

  return (
    <>
      <header className={`header83 ${className ?? ''}`}>
        <div className="d-flex align-items-center">
          <div className="flex-15">
            <div className="logo" onClick={() => navigate('/')}>
              <img src={require('../../assets/images/flex-logo.png')} className="img-responsive" />
            </div>
          </div>

          <div className="flex-55">
            {isLoggedIn && (
              <nav>
                <ul className="list-style-none cursor-pointer">
                  <li onClick={() => navigate('/')}>
                    Account
                  </li>
                  <li className="disabled-item">
                    System <i className="far fa-chevron-down"></i>
                  </li>
                  <li className="disabled-item">
                    Analytics <i className="far fa-chevron-down"></i>
                  </li>
                  <li className="disabled-item">
                    IAM <i className="far fa-chevron-down"></i>
                  </li>
                  <li className="disabled-item">
                    Security <i className="far fa-chevron-down"></i>
                  </li>
                </ul>
              </nav>
            )}
          </div>

          <div className="flex-30 justify-content-end">
            {isLoggedIn &&
              <>
                <UserProfile />
                <Logout />
              </>}
          </div>
        </div>
      </header>
    </>
  );
}

export default Header83;