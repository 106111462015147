import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/main.scss';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import azureConfig from './pages/Login/msalConfig';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';

const msalInstance = new PublicClientApplication({
  auth: azureConfig.auth,
  cache: azureConfig.cache
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <div className="content-wrapper83">
        <App />
      </div>
    </Provider>
  </MsalProvider>
);

reportWebVitals();
