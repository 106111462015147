export const CustomersForm = {
    title: "Create Account",
    titleIcon: "fal fa-users",
    fields: [
        {
            key: "customerName",
            type: "input",
            label: "Customer Name",
            inputType: "text",
            placeholder: "Acme",
            minCharacters: 4,
            maxCharacters: 12
        },
        {
            key: "customerEmail",
            type: "input",
            label: "Customer Email",
            inputType: "text",
            placeholder: "admin@acme.com",
            validationType: "email"
        },
        {
            key: "accountName",
            type: "input",
            label: "Account Name",
            inputType: "text",
            placeholder: "Acme Holdings",
            minCharacters: 4,
            maxCharacters: 12
        },
        {
            key: "environment",
            label: "Environment",
            type: "select",
            placeholder: "Select",
            options: [
                { value: "prod", label: "Production" },
                { value: "stage", label: "Stage" },
                { value: "dev", label: "Development" },
                { value: "load", label: "Load" },
                { value: "other", label: "Other" },
            ],
        },
        {
            key: "application",
            type: "input",
            label: "Application",
            inputType: "text",
            placeholder: "Acme Digital Portal",
            minCharacters: 4,
            maxCharacters: 12
        },
    ],

    actions: {
        submit: {
            label: "Create Account",
            icon: "fal fa-users"
        }
    }
}