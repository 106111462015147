/************************************************************************
 * 83incs CONFIDENTIAL
 * ***********************************************************************
 *
 *  [2017] - [2022] 83incs Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of 83incs Ltd, IoT83 Ltd, its suppliers (if any), its subsidiaries (if any) and
 * Source Code Licenses (if any).  The intellectual and technical concepts contained
 * herein are proprietary to 83incs Ltd, IoT83 Ltd, its subsidiaries (if any) and
 * Source Code Licenses (if any) and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from 83incs Ltd or IoT83 Ltd.
 ****************************************************************************
 */

/**
 *
 * Form83
 *
 */

import { cloneDeep } from "lodash";
import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from "react";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import Button83 from "../Button83";
import { v4 as uuidv4 } from "uuid"

let intialEnvVariable = [{
	key: "",
	value: "",
	id: uuidv4()
}]

export default forwardRef(({
	actionButtonDisable = false,
	customclass,
	id,
	linkButtonHandler,
	formFields,
	formData,
	submitHandler,
	onFormChange,
	iconURL,
	submitButtonIcon,
	envVariableData,
	children }, ref) => {

	const [inputFields, setInputFields] = useState(formData ? formData : null);
	const [errorFields, setErrorFields] = useState([]);
	const [emailError, setEmailError] = useState(false);
	const [inputError, setInputError] = useState({})
	const [envVariable, setEnvVariable] = useState(envVariableData ? envVariableData : intialEnvVariable)
	const errorFieldsRef = useRef([]);
	const { title } = formFields

	useImperativeHandle(ref, () => ({
		formSubmit: (event) => onSubmit(event, formFields, inputFields, envVariable)
	}));

	const getAdditionalClasses = (classes) => {
		let classResult = "";
		classes.forEach((fieldClass) => {
			classResult = `${classResult} ${fieldClass}`;
		});
		return classResult;
	};

	const comparatorFunction = (input, expression, count) => {
		switch (expression) {
			case ">": {
				return input > count;
			}
			case "<": {
				return input < count;
			}
			case "==": {
				return input == count;
			}
			case "===": {
				return input === count;
			}
			case ">=": {
				return input >= count;
			}
			case "<=": {
				return input <= count;
			}
		}
	};

	const fieldIsRequired = (field, value) => {
		if (field?.isRequired) {
			if (value?.[field.key]) {
				if (errorFields.includes(field.key)) {
					let errorFieldsClone = cloneDeep(errorFields);
					errorFieldsClone = errorFieldsClone.filter((item) => item !== field.key);
					setErrorFields(errorFieldsClone);
				}
				return true;
			} else {
				if (!errorFields.includes(field.key)) {
					setErrorFields((prevState) => [...prevState, field.key])
					return false;
				}
			}
		} else {
			return null;
		}
	};

	const fieldLengthValid = (field, value) => {
		if (field && value && value[field.key] && field.validation && field.validation.rule && field.validation.rule.length) {
			if (comparatorFunction(value[field.key].length, field.validation.rule.length.expression, field.validation.rule.length.count)) {
				if (errorFields.includes(field.key)) {
					let errorFieldsClone = cloneDeep(errorFields);
					errorFieldsClone = errorFieldsClone.filter((item) => item !== field.key);
					setErrorFields(errorFieldsClone);
				}
				return true;
			} else {
				if (!errorFields.includes(field.key)) {
					setErrorFields((prevState) => [...prevState, field.key]);
					return false;
				}
			}
		} else {
			return null;
		}
	};

	const fieldRegexValid = (field, value) => {
		if (field && value && value[field.key] && field.validation && field.validation.regex) {
			if (new RegExp(field.validation.regex).test(value[field.key])) {
				if (errorFields.includes(field.key)) {
					let errorFieldsClone = cloneDeep(errorFields);
					errorFieldsClone = errorFieldsClone.filter((item) => item !== field.key);
					setErrorFields(errorFieldsClone);
				}
				return true;
			} else {
				if (!errorFields.includes(field.key)) {
					setErrorFields((prevState) => [...prevState, field.key]);
					return false;
				}
			}
		} else {
			return null;
		}
	};



	const removeNull = (obj) => {
		Object.keys(obj).forEach((key) => (obj[key] && typeof obj[key] === "object" && removeNull(obj[key])) || ((obj[key] === undefined || obj[key] === null || obj[key] === "") && delete obj[key]));
		return obj;
	};

	function clearEmpties(obj) {
		for (let key in obj) {
			if (!obj[key] || typeof obj[key] !== "object") { //|| typeof obj[key] !== "date"
				continue;
			}
			clearEmpties(obj[key]);
			if (Object.keys(obj[key]).length === 0) {
				delete obj[key];
			}
		}
		return obj;
	}

	const validateField = (field, value) =>
		new Promise((resolve, reject) => {
			if (Array.isArray(field)) {
				return field.map((childField, dependentFields) => validateField(childField, value));
			} else {
				value = removeNull(value);
				value = clearEmpties(value);
				let result = [
					fieldIsRequired(field, value),
					fieldLengthValid(field, value),
					fieldRegexValid(field, value),
				];
				if (result.some((item) => item === false)) {
					reject("validation error");
				} else {
					resolve("validation completed");
				}
			}
		});

	const validateEmail = (email) => {
		const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		return regex.test(email);
	};

	function setStateHandler(type, parent, value, inputType, validationType, minCharacters, maxCharacters, isRequired) {

		let inputFieldsClone = cloneDeep(inputFields);
		let errorFieldsClone = cloneDeep(errorFields);

		if (errorFieldsClone.includes(parent)) {
			errorFieldsClone = errorFieldsClone.filter((item) => item !== parent);
			setErrorFields(errorFieldsClone);
		}

		switch (type) {
			case "tags":
			case "checkbox":
				if (inputFieldsClone?.[parent]?.length > 0) {
					if (inputFieldsClone[parent].find((item) => type === "tags" ? JSON.stringify(item) === JSON.stringify(value) : item === value)) {
						inputFieldsClone[parent] = inputFieldsClone[parent].filter((item) => {
							return type === "tags" ? JSON.stringify(item) !== JSON.stringify(value) : item !== value;
						});
					} else {
						inputFieldsClone[parent].push(value);
					}
				} else {
					inputFieldsClone = { ...inputFieldsClone, [parent]: [value] };
				}
				setInputFields(inputFieldsClone);
				break;

			case "inputText":
				if (inputType === 'date') {
					setInputFields((prev) => ({ ...prev, [parent]: new Date(value).getTime() }));
				}
				else {
					setInputFields((prev) => ({ ...prev, [parent]: value }));
				}
				if (validationType === "email") {
					const isValidEmail = validateEmail(value);
					setEmailError(!isValidEmail);
				}
				if (value.length < minCharacters || value.length > maxCharacters) {
					setInputError((prev) => ({ ...prev, [parent]: true }))
				} else {
					setInputError((prev) => ({ ...prev, [parent]: false }))
				}
				break;
			case "date":
			case "radio":
			case "select":
			case "range":
			case "multipleSelect":
			case "amount":
				setInputFields((prev) => ({ ...prev, [parent]: value }));
				break;
		}
	};

	const isChecked = (type, parent, value) => {

		if (inputFields?.[parent]) {
			switch (type) {
				case "checkbox":
					return inputFields[parent]?.find((item) => item === value) ? true : false;
				case "radio":
					return inputFields[parent] === value ? true : false;
			}
		} else {
			return false;
		}
	};

	const onSubmit = (event, formFields, value, envVariable) => {
		event?.preventDefault()

		if (formFields?.fields?.length > 0 && value) {
			formFields.fields.map((field, index) => {
				// validateField(field, value).then(() => {
				if (index === formFields.fields.length - 1 && errorFieldsRef.current.length === 0) {
					if (submitHandler) {
						submitHandler(value, envVariable)
					}
					setInputFields(null);
					setEnvVariable(intialEnvVariable)
				}
				// }).catch((error) => {
				// 	console.error("validation error: ", error)
				// })
			});
		}
	};

	const onReset = () => {
		setInputFields(null);
	};

	const envVariableValueHandler = (keytype, id) => {
		const matchedObj = envVariable.find((obj) => obj.id === id);
		if (matchedObj) {
			return keytype === 'key' ? matchedObj.key : matchedObj.value;
		}
		return '';
	};


	const envVariableHandler = (keytype, e) => {
		const updatedId = e.target.id;
		const updatedValue = e.target.value;
		const updatedEnvVariable = envVariable.map((obj) => {
			if (obj.id === updatedId) {

				if (keytype === 'key') {
					return { ...obj, key: updatedValue };
				} else if (keytype === 'value') {
					return { ...obj, value: updatedValue };
				}
			}
			return obj;
		});

		setEnvVariable(updatedEnvVariable);
	};

	const addNewFields = (event) => {
		event.preventDefault()
		setEnvVariable(prev => ([...prev, {
			'key': '',
			"value": "",
			id: uuidv4()
		}]))
	}

	const deleteField = (event, idToDelete) => {
		event.preventDefault()
		const envVariableClone = JSON.parse(JSON.stringify(envVariable))
		const newArray = envVariableClone.filter((obj) => obj.id !== idToDelete);
		setEnvVariable(newArray)
	}

	const getWidgetsHtml = (field) => {

		switch (field.type) {
			case 'input': {
				if (field.inputType === "radio" || field.inputType === "checkbox") {
					return field.childFields && field.childFields.length > 0 && (
						<React.Fragment>
							<ul className={`list-style-none ${field?.additionalClasses?.ul?.length > 0 ? getAdditionalClasses(field.additionalClasses.ul) : ""}`}>
								{field.childFields.map((radioItem) => {
									return <li key={radioItem.key} className={`display-flex align-items-center ${field?.additionalClasses?.li?.length > 0 ? getAdditionalClasses(field.additionalClasses.li) : ""}`}>
										<input type={field.inputType} id={radioItem.key} name={field.key} className={`margin-right-10 form-check${field?.additionalClasses?.input?.length > 0 ? getAdditionalClasses(field.additionalClasses.input) : ""}`} onChange={() => setStateHandler(field.inputType, field.key, radioItem.key)} checked={isChecked(field.inputType, field.key, radioItem.key)} />
										<label htmlFor={radioItem.key}>{radioItem.label}</label>
									</li>
								})}
								{errorFields.includes(field.key) && <span className="error-text">
									{field?.validation?.errorMessage}
								</span>}
							</ul>
							{inputFields?.[field.key] && (field?.dependentFields?.[inputFields?.[field.key]]?.map((fields) => getFormHTML(fields)))}
						</React.Fragment>
					)
				} else {
					return <React.Fragment>
						<div className="form83-group">
							<input
								type={field.inputType ? field.inputType : "text"}
								id={field.key}
								disabled={field?.disabled ?? false}
								placeholder={field.placeholder}
								style={field.icon ? {} : { paddingLeft: "10px" }}
								className={`form83-control${field?.additionalClasses?.input?.length > 0 ? getAdditionalClasses(field.additionalClasses.input) : ""}`}
								{...(field.inputType === "number" && field.validation && field.validation.rule && { ...(field.validation.rule.max !== null || field.validation.rule.max !== undefined ? { max: field.validation.rule.max } : {}), ...(field.validation.rule.min !== null || field.validation.rule.min !== undefined ? { min: field.validation.rule.min } : {}), })}
								value={field.inputType === 'date' ? inputFields?.[field.key] ? formatDate(new Date(inputFields[field.key])) : formatDate(new Date()) : inputFields?.[field.key] ? inputFields[field.key] : ""}
								onChange={(e) => setStateHandler("inputText", field.key, e.target.value, field.inputType, field.validationType, field.minCharacters, field.maxCharacters)}
							/>
							{emailError && field.validationType === 'email' && <span style={{ color: 'red' }}>Invalid email format</span>}
							<i className={field?.icon}></i>
							{inputError[field.key] && <span style={{ color: 'red' }}>Characters limit 4-12</span>}
							<i className={field?.icon}></i>

						</div>
						{errorFields.includes(field.key) && <p className="error-text" style={{ color: 'red' }}>
							{field?.validation?.errorMessage}
						</p>}
					</React.Fragment>
				}
			}
			case 'textarea': {
				return <React.Fragment>
					<div className="form83-group">
						<textarea
							id={field.key}
							placeholder={field.placeholder}
							style={field.icon ? {} : { paddingLeft: "10px" }}
							className={`form83-control${field?.additionalClasses?.input?.length > 0 ? getAdditionalClasses(field.additionalClasses.input) : ""}`}
							{...(field.inputType === "number" && field.validation && field.validation.rule && { ...(field.validation.rule.max !== null || field.validation.rule.max !== undefined ? { max: field.validation.rule.max } : {}), ...(field.validation.rule.min !== null || field.validation.rule.min !== undefined ? { min: field.validation.rule.min } : {}), })}
							value={inputFields?.[field.key] ? inputFields[field.key] : ""}
							onChange={(e) => setStateHandler("inputText", field.key, e.target.value)}
						/>
						<i className={field?.icon}></i>
					</div>
					{errorFields.includes(field.key) && <p className="error-text" style={{ color: 'red' }}>
						{field?.validation?.errorMessage}
					</p>}
				</React.Fragment>
			}
			case 'select': {
				const selectedOption = inputFields?.[field.key]
					? field.options.find(option => option.value === inputFields[field.key])
					: null;

				return (
					<div className={`${field.additionalClasses && field.additionalClasses.length > 0 ? getAdditionalClasses(field.additionalClasses) : ""}`}>
						<React.Fragment>
							<div className="display-flex flex-wrap">
								<Select
									className="form83-control-multi-select"
									classnameprefixix="form83-control-multi-select"
									value={selectedOption}
									options={field.options}
									onChange={(element) => setStateHandler("select", field.key, element.value)}
								/>
								{field.actionButton && (
									<Button83
										id={uuidv4()}
										label={field?.actionButton?.label}
										className={field?.actionButton?.className}
										variant={field?.actionButton?.variant}
										onClick={field?.actionButton?.onClick}
									/>
								)}
							</div>
							{selectedOption && field?.dependentFields?.[selectedOption.value]?.map((fields) => getFormHTML(fields))}
						</React.Fragment>
					</div>
				);
			}

			case 'multipleSelect': {
				return <div
					className={`form83-control-multi-select${field.additionalClasses && field.additionalClasses.length > 0 ? getAdditionalClasses(field.additionalClasses) : ""}`} >
					<Select
						isMulti
						classnameprefix="form83-control-multi-select"
						options={field.options}
						value={(inputFields?.[field.key] && field?.options?.filter(option => inputFields[field.key]?.some(obj => obj.value === option.value))) || []}
						onChange={(element) => { setStateHandler("multipleSelect", field.key, element) }}
					/>
					{inputFields?.[field.key]?.length >= 1 && (field?.dependentFields && inputFields[field.key]?.map(item => {
						if (field?.dependentFields[item.value]) {
							return field?.dependentFields[item.value]?.map((fields) => getFormHTML(fields))
						} else {
							return null
						}
					})
					)}
				</div>
			}
			// case 'tags': {
			// 	return <div className={`${field.additionalClasses && field.additionalClasses.length > 0 ? getAdditionalClasses(field.additionalClasses) : ""}`} >
			// 		<ReactTags tags={inputFields && inputFields[field.key] ? inputFields[field.key] : []} handleAddition={(tag) => setStateHandler("tags", field.key, tag)} handleDelete={(i) => setStateHandler("tags", field.key, inputFields[field.key][i])} />
			// 	</div>
			// }
			case 'date': {
				return <div className={`${field.additionalClasses && field.additionalClasses.length > 0 ? getAdditionalClasses(field.additionalClasses) : ""}`} >

					<DateTimePicker format="yyyy-MM-dd" disableClock={field.disableClock} value={inputFields?.[field.key] ? new Date(inputFields?.[field.key]) : new Date()} onChange={(value) => setStateHandler("date", field.key, value)} />
					{errorFields.includes(field.key) && <span className="error-text" style={{ color: 'red' }}>
						{field?.validation?.errorMessage}
					</span>}
				</div>
			}
			case 'slider': {
				return <div >
					<input
						type={field.inputType ? field.inputType : "range"}
						id={field.key}
						placeholder={field.placeholder}
						style={field.icon ? {} : { paddingLeft: "10px" }}
						className={`form-slider${field?.additionalClasses?.input?.length > 0 ? getAdditionalClasses(field.additionalClasses.input) : ""}`}
						{...(field.inputType === "number" && field.validation && field.validation.rule && { ...(field.validation.rule.max !== null || field.validation.rule.max !== undefined ? { max: field.validation.rule.max } : {}), ...(field.validation.rule.min !== null || field.validation.rule.min !== undefined ? { min: field.validation.rule.min } : {}), })}
						value={inputFields?.[field.key] ? inputFields[field.key] : 0}
						onChange={(e) => setStateHandler("range", field.key, e.target.value)}
					/>
				</div>
			}
			case 'keyValueTable': {
				return (
					<table width="100%" className="table table-bordered">
						<thead>
							<tr>
								<th width="40%">Key</th>
								<th width="40%">Value</th>
								<th width="20%">Actions</th>
							</tr>
						</thead>
						<tbody>
							{envVariable.map((keyValue, index) => (
								<tr key={keyValue?.id} >
									<td>
										<input
											type="text"
											placeholder="Enter..."
											className="form83-control"
											id={keyValue?.id}
											value={envVariableValueHandler('key', keyValue?.id)}
											onChange={(e) => envVariableHandler('key', e)}
										/>
									</td>
									<td>
										<input
											type="text"
											placeholder="Enter..."
											className="form83-control"
											value={envVariableValueHandler('value', keyValue?.id)}
											id={keyValue?.id}
											onChange={(e) => envVariableHandler('value', e)}
										/>
									</td>
									<td>
										{index !== envVariable.length - 1 ? (
											// Render "Delete" button for all rows except the last one
											<div className="display-flex action-buttons-group justify-content-center">
												<Button83
													className="button83-danger"
													icon="fal fa-trash-alt"
													dataTip="Delete"
													dataFor="delete"
													onClick={(event) => deleteField(event, keyValue?.id)}
												/>
											</div>
										) : null}
										{index === envVariable.length - 1 ? (
											// Render "Add" button only for the last row
											<div className="display-flex action-buttons-group justify-content-center">
												<Button83
													className="button83 button83-icon-primary"
													icon="far fa-plus"
													dataTip="Add"
													dataFor="Add"
													onClick={(event) => addNewFields(event)}
												/>
											</div>
										) : null}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				);
			}

			case 'button': {
				return <Button83 onClick={field?.ButtonHandler} className="button83-outlined-primary w-100" label={field?.buttonLabel}></Button83>
			}
		}
	}

	const getFormHTML = (field, rowCount) => {
		if (Array.isArray(field)) {
			return <div className="display-flex flex-wrap ">
				{field.map((childField) => getFormHTML(childField, field.length))}
			</div>
		} else {
			return <div key={field.key} className={`form83-group ${field?.additionalClasses?.group?.length > 0 ? getAdditionalClasses(field.additionalClasses.group) : ""}`} style={rowCount && { flexBasis: `${100 / rowCount}%` }} >
				<label className={`form83-label${field?.additionalClasses?.label?.length > 0 ? getAdditionalClasses(field.additionalClasses.label) : ""}`}>
					{`${field.label} ${field.isRequired ? "*" : ""} : `}
				</label>
				{getWidgetsHtml(field)}
			</div>
		}
	};

	useEffect(() => {
		errorFieldsRef.current = errorFields;
	}, [errorFields]);

	useEffect(() => {
		inputFields && onFormChange && onFormChange(inputFields, envVariable)
	}, [inputFields, envVariable])

	useEffect(() => {
		if (JSON.stringify(inputFields) !== JSON.stringify(formData)) {
			setInputFields(formData)
		}
	}, [formFields, formData])


	function padTo2Digits(num) {
		return num.toString().padStart(2, '0');
	}

	function formatDate(date) {
		return (
			[
				date.getFullYear(),
				padTo2Digits(date.getMonth() + 1),
				padTo2Digits(date.getDate()),
			].join('-')
		);
	}

	const handleSubmitDisabled = () => {
		if (title === "Create Account") {
			return (emailError || Object.values(inputError).find(el => el === true) || inputFields === undefined || (formFields?.fields && Object.keys(inputFields || {}).length !== formFields.fields.length) || Object.values(inputFields || {}).includes(''))
		} else {
			return false
		}
	}

	return (
		<React.Fragment>
			{iconURL && <link rel="stylesheet" href={iconURL}></link>}
			{formFields && <form id={id} className='form83'>
				{formFields.title && <div className="form83-header">
					<h6>
						<i className={formFields.titleIcon}></i>
						{formFields.title}
					</h6>
				</div>}
				<div className="form83-body form83-scrollable-body-lg">
					{formFields.fields && formFields.fields.length > 0 && formFields.fields.map((field) => getFormHTML(field))}
					{children}
				</div>
				{formFields.actions && <div className=" form83-footer ">
					<div className="form83-action-buttons">
						{formFields.actions.reset && formFields.actions.reset.hidden !== true && <Button83
							className="button83-outlined-danger"
							onClick={() => onReset()}
							icon="far fa-sync-alt"
							label={formFields.actions.reset.label ? formFields.actions.reset.label : "Reset"}
						/>}
						{formFields.actions.submit && formFields.actions.submit.hidden !== true &&
							<Button83
								disabled={actionButtonDisable || handleSubmitDisabled()}
								className={`button83-primary`}
								onClick={(event) => onSubmit(event, formFields, inputFields, envVariable)}
								label={formFields.actions.submit.label ? formFields.actions.submit.label : "Submit"}
								icon={submitButtonIcon}

							/>}
					</div>
				</div>}
			</form>}
		</React.Fragment>
	);
})
