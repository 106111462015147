import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/Login/LoginPage';
import Customers from './pages/Customers/Customers';
import ActionTec from './pages/ActionTec/ActionTec';
import ErrorPage from './pages/ErrorPage';
import { useIsAuthenticated } from '@azure/msal-react';
import { ToastContainer } from 'react-toastify';
import Loader83 from './components/Loader83';


const App = () => {
    const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated !== null) {
            setIsCheckingAuth(false);
        }
    }, [isAuthenticated]);

    if (isCheckingAuth) {
        return <Loader83 />
    }

    return (
        <>
            <BrowserRouter>
                <Routes>
                    {!isAuthenticated ? (
                        <Route path="*" element={<LoginPage />} />
                    ) : (
                        <>
                            <Route path="*" element={<ErrorPage />} />
                            <Route path="/" element={<Customers />} />
                            <Route path="/customers" element={<Customers />} />
                            <Route path="/actionTec" element={<ActionTec />} />
                            <Route
                                path="/login"
                                element={<Navigate to="/" replace />}
                            />
                        </>
                    )}
                </Routes>
            </BrowserRouter>
            <ToastContainer position="top-right" newestOnTop />
        </>
    );
};

export default App;



