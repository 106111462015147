/**
 *
 * NoDataFound83
 *
 */

import React, { memo } from "react";

function NoDataFound83({ noDataFoundImage, text }) {
    return (
        <>
            <div className="position-relative h-100 no-data83-wrapper">
                <div className='no-data-found83'>
                    <div className="no-data-found83-container">
                        <div className="no-data-found83-image">
                            <img src={noDataFoundImage ? noDataFoundImage : (require('../../assets/images/noDataFound.png'))} />
                        </div>

                        <p>Oops!</p>
                        <h6>There is no data to display.</h6>
                        <p>{text || ""}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

NoDataFound83.propTypes = {};

export default memo(NoDataFound83);
