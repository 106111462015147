import { matchSorter } from 'match-sorter'
import React, { useState, useRef, useEffect, forwardRef } from 'react'

export const EditableCell = ({
	value: initialValue,
	row: { index },
	column: { id },
	updateMyData,
	editable,
}) => {
	const [value, setValue] = useState(initialValue)

	const onChange = e => {
		setValue(e.target.value)
	}

	const onBlur = () => {
		updateMyData(index, id, value)
	}

	React.useEffect(() => {
		setValue(initialValue)
	}, [initialValue])

	if (!editable) {
		return `${initialValue}`
	}

	return <input value={value} onChange={onChange} onBlur={onBlur} />
}


export function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

fuzzyTextFilterFn.autoRemove = val => !val

export const IndeterminateCheckbox = forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = useRef()
		const resolvedRef = ref || defaultRef

		useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate
		}, [resolvedRef, indeterminate])

		return (
			<React.Fragment>
				<input type="checkbox" ref={resolvedRef} {...rest} />
			</React.Fragment>
		)
	}
)

export const filterTypes =
	() => ({
		fuzzyText: fuzzyTextFilterFn,
		text: (rows, id, filterValue) => {
			return rows.filter(row => {
				const rowValue = row.values[id]
				return rowValue !== undefined
					? String(rowValue)
						.toLowerCase()
						.startsWith(String(filterValue).toLowerCase())
					: true
			})
		},
	})
