// Auth.js
export const saveAuthState = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getAuthState = (key) => {
    const value = localStorage.getItem(key);
    return value;
};

export const removeAuthState = (key) => {
    localStorage.clear();
    sessionStorage.clear()
    sessionStorage.removeItem(key)
};
