import React from "react";

function BreadCrumbs83() {
  return (
    <React.Fragment>
      <div className="breadCrumbs83">
        <ul className="list-style-none">
          <li>Account <i className="far fa-chevron-right"></i></li>
          <li className="active"> Customers</li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default BreadCrumbs83;