import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { msalInstance } from "../pages/Login/msalConfig";
import { handleLogout, setActiveAccount } from "./commonUtils";
import { toast } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import { setApiResponse } from "./apiResponseSlice";
import axios from "axios";


const baseUrl = window.APP_CONFIG.API_BASE_URL
export const baseQuery = fetchBaseQuery({
    baseUrl: window.APP_CONFIG.API_BASE_URL,
    prepareHeaders: (headers) => {
        const token = localStorage.token;
        headers.set("Content-Type", "application/json")
        if (token) {
            headers.set("Authorization", `${token}`)

        }
        return headers;
    }
})

const apiEndpoints = [
    'flex83/account/api/v6?sortBy=createdAt&sortType=DESC&offset=0&max=10',
    'https://graph.microsoft.com/v1.0/me'
]

const baseQueryWithReauth = async (args, api, extraOptions) => {
    try {

        let result = await baseQuery(args, api, extraOptions);
        const handleErrorResponse = () => {
            const errorMessage =
                result?.error?.data?.errorMessage?.[0] ||
                result?.error?.data?.error;
            toast(errorMessage, { type: 'error' });
        };

        const handleTokenRefresh = () => {
            setActiveAccount();
            const account = msalInstance.getActiveAccount();
            const request = {
                scopes: [window.APP_CONFIG.MS_SCOPE] // Add the required scopes for the refreshed token
            };
            msalInstance
                .acquireTokenSilent(request, account)
                .then(async response => {
                    const refreshedToken = response.accessToken;
                    localStorage.removeItem('token');
                    localStorage.setItem('token', `Bearer ${refreshedToken}`);
                    if (refreshedToken) {
                        const headers = {
                            'Authorization': refreshedToken,
                            "Content-Type": "application/json"
                        };
                        const responses = await Promise.all(
                            apiEndpoints.map(endpoint =>
                                baseQuery(endpoint, api, extraOptions)
                                    .then(res => res?.data?.data || res?.data)
                                    .catch(error => {
                                        console.error('Error fetching data:', error);
                                        throw error;
                                    })
                            )
                        );
                        axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
                            responseType: 'arraybuffer',
                            headers
                        })
                            .then((response) => {
                                const base64Image = btoa(
                                    new Uint8Array(response.data).reduce(
                                        (data, byte) => data + String.fromCharCode(byte),
                                        ''
                                    )
                                );
                                api.dispatch(setApiResponse({
                                    profilePic: `data:image/jpeg;base64, ${base64Image}`,
                                    customers: responses[0],
                                    userDetails: responses[1]
                                }));
                            })
                            .catch((error) => {
                                console.log('Error fetching user profile picture:', error);
                                api.dispatch(setApiResponse({
                                    customers: responses[0],
                                    userDetails: responses[1]
                                }));
                            });

                    } else {
                        toast('Error refreshing token', { type: 'error' });
                        handleLogout()
                    }
                })
                .catch(error => {
                    toast(error, { type: 'error' });
                    handleLogout()
                });

        }

        switch (result?.error?.status) {
            case 401:
            case 403:
                let errorMsg = result?.error?.data?.errorMessage?.[0] || result?.error?.data?.error

                switch (errorMsg) {
                    case "Access token is required.":
                    case "Please provide a valid token.":
                    case "Please provide a valid token, current token is expired.":
                        handleTokenRefresh()

                        break;
                    default:
                        handleErrorResponse()
                        handleTokenRefresh()
                }
                break;
            case 400:
            case 500:
            case 404:
                handleErrorResponse();
                break;
            case 502:
                if (
                    result?.error?.name === 'TypeError' &&
                    result?.error?.message === 'Failed to fetch'
                ) {
                    toast('CORS Error: Make sure the API server allows requests from this domain.', {
                        type: 'error'
                    });
                } else {
                    toast('An error occurred while fetching data.', {
                        type: 'error'
                    });
                }
                break;

            default:
                return result;
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({})
})



