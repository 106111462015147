import { createSlice } from '@reduxjs/toolkit';

const apiResponseSlice = createSlice({
    name: 'apiResponse',
    initialState: null,
    reducers: {
        setApiResponse: (state, action) => {
            return action.payload;
        },
    },
});

export const { setApiResponse } = apiResponseSlice.actions;
export default apiResponseSlice.reducer;